import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'admin_appraisal_index' */ '~/pages/admin/appraisal/Index');

const TemplateIndex = () => import(/* webpackChunkName: 'admin_appraisal_template_index' */ '~/pages/admin/appraisal/template/Index');
const TemplateList = () => import(/* webpackChunkName: 'admin_appraisal_template_list' */ '~/pages/admin/appraisal/template/List');
const TemplateCreate = () => import(/* webpackChunkName: 'admin_appraisal_template_create' */ '~/pages/admin/appraisal/template/Create');
const TemplateEdit = () => import(/* webpackChunkName: 'admin_appraisal_template_edit' */ '~/pages/admin/appraisal/template/Edit');
const TemplateDetail = () => import(/* webpackChunkName: 'admin_appraisal_template_detail' */ '~/pages/admin/appraisal/template/Detail');

const ScoreIndex = () => import(/* webpackChunkName: 'admin_appraisal_score_index' */ '~/pages/admin/appraisal/score/Index');
const ScoreList = () => import(/* webpackChunkName: 'admin_appraisal_score_list' */ '~/pages/admin/appraisal/score/List');
const ScoreCreate = () => import(/* webpackChunkName: 'admin_appraisal_score_create' */ '~/pages/admin/appraisal/score/Create');
const ScoreEdit = () => import(/* webpackChunkName: 'admin_appraisal_score_edit' */ '~/pages/admin/appraisal/score/Edit');
const ScoreDetail = () => import(/* webpackChunkName: 'admin_appraisal_score_detail' */ '~/pages/admin/appraisal/score/Detail');

const CycleIndex = () => import(/* webpackChunkName: 'admin_appraisal_cycle_index' */ '~/pages/admin/appraisal/cycle/Index');
const CycleList = () => import(/* webpackChunkName: 'admin_appraisal_cycle_list' */ '~/pages/admin/appraisal/cycle/List');
const CycleCreate = () => import(/* webpackChunkName: 'admin_appraisal_cycle_create' */ '~/pages/admin/appraisal/cycle/Create');
const CycleEdit = () => import(/* webpackChunkName: 'admin_appraisal_cycle_edit' */ '~/pages/admin/appraisal/cycle/Edit');
const CycleDetail = () => import(/* webpackChunkName: 'admin_appraisal_cycle_detail' */ '~/pages/admin/appraisal/cycle/Detail');

const ReviewIndex = () => import(/* webpackChunkName: 'admin_appraisal_review_index' */ '~/pages/admin/appraisal/review/Index');
const ReviewList = () => import(/* webpackChunkName: 'admin_appraisal_review_list' */ '~/pages/admin/appraisal/review/List');
const ReviewDetail = () => import(/* webpackChunkName: 'admin_appraisal_review_detail' */ '~/pages/admin/appraisal/review/Detail');

const IndisciplineIndex = () => import(/* webpackChunkName: 'admin_appraisal_indiscipline_index' */ '~/pages/admin/appraisal/indiscipline/Index');
const IndisciplineList = () => import(/* webpackChunkName: 'admin_appraisal_indiscipline_list' */ '~/pages/admin/appraisal/indiscipline/List');
const IndisciplineCreate = () => import(/* webpackChunkName: 'admin_appraisal_indiscipline_create' */ '~/pages/admin/appraisal/indiscipline/Create');
const IndisciplineEdit = () => import(/* webpackChunkName: 'admin_appraisal_indiscipline_edit' */ '~/pages/admin/appraisal/indiscipline/Edit');

const RecommendationIndex = () => import(/* webpackChunkName: 'admin_appraisal_recommendation_index' */ '~/pages/admin/appraisal/recommendation/Index');
const RecommendationList = () => import(/* webpackChunkName: 'admin_appraisal_recommendation_list' */ '~/pages/admin/appraisal/recommendation/List');
const RecommendationCreate = () => import(/* webpackChunkName: 'admin_appraisal_recommendation_create' */ '~/pages/admin/appraisal/recommendation/Create');
const RecommendationEdit = () => import(/* webpackChunkName: 'admin_appraisal_recommendation_edit' */ '~/pages/admin/appraisal/recommendation/Edit');

export default {
  path: 'performance-appraisal',
  component: r(Index),
  children: [
    {
      path: 'template',
      component: r(TemplateIndex),
      children: [
        { path: '', name: 'admin_appraisal_template_list', component: r(TemplateList) },
        { path: 'new', component: r(TemplateCreate), name: 'admin_appraisal_template_create' },
        { path: ':id/edit', component: r(TemplateEdit), name: 'admin_appraisal_template_edit' },
        { path: ':id', component: r(TemplateDetail), name: 'admin_appraisal_template_detail' },
      ],
    },
    {
      path: 'score',
      component: r(ScoreIndex),
      children: [
        { path: '', name: 'admin_appraisal_score_list', component: r(ScoreList) },
        { path: 'new', component: r(ScoreCreate), name: 'admin_appraisal_score_create' },
        { path: ':id/edit', component: r(ScoreEdit), name: 'admin_appraisal_score_edit' },
        { path: ':id', component: r(ScoreDetail), name: 'admin_appraisal_score_detail' },
      ],
    },
    {
      path: 'cycle',
      component: r(CycleIndex),
      children: [
        { path: '', name: 'admin_appraisal_cycle_list', component: r(CycleList) },
        { path: 'new', component: r(CycleCreate), name: 'admin_appraisal_cycle_create' },
        { path: ':id/edit', component: r(CycleEdit), name: 'admin_appraisal_cycle_edit' },
        { path: ':id', component: r(CycleDetail), name: 'admin_appraisal_cycle_detail' },
      ],
    },
    {
      path: 'review',
      component: r(ReviewIndex),
      children: [
        { path: '', name: 'admin_appraisal_review_list', component: r(ReviewList) },
        { path: ':id', component: r(ReviewDetail), name: 'admin_appraisal_review_detail' },
      ],
    },
    {
      path: 'indiscipline',
      component: r(IndisciplineIndex),
      children: [
        { path: '', name: 'admin_appraisal_indiscipline_list', component: r(IndisciplineList) },
        { path: 'new', component: r(IndisciplineCreate), name: 'admin_appraisal_indiscipline_create' },
        { path: ':id/edit', component: r(IndisciplineEdit), name: 'admin_appraisal_indiscipline_edit' },
      ],
    },
    {
      path: 'recommendation',
      component: r(RecommendationIndex),
      children: [
        { path: '', name: 'admin_appraisal_recommendation_list', component: r(RecommendationList) },
        { path: 'new', component: r(RecommendationCreate), name: 'admin_appraisal_recommendation_create' },
        { path: ':id/edit', component: r(RecommendationEdit), name: 'admin_appraisal_recommendation_edit' },
      ],
    },
  ],
};
